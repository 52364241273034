.secondary-section .block-container ul {
    padding-left: 18px;
}

.secondary-section .block-container .icon-label {
    display: flex;
}

.secondary-section .block-container .icon-label a {
    text-decoration: none;
    color: inherit;
}

.secondary-section .block-container .icon-label svg {
    height: 16px;
    width: 10px;
    margin: 0 4px
}

.linkedin-container a {
    color: #0c5394;
    text-decoration: none;
    word-break: break-all;
}