.App {
  padding: 10px 20px;
}

.print-btn-container {
  text-align: right;
  margin-bottom: 12px;
}

.print-btn-container .print-btn {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 10px;
}

.print-btn-container .print-btn:hover {
  background-color: #e9f5ff;
}

.print-btn-container .print-btn img {
  height: 30px;
  width: 30px;
}

.resume-container {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px -15px 10px -10px rgba(0, 0, 0, 0.45),
    15px 0px 10px -15px rgba(0, 0, 0, 0.45),
    0px 15px 10px -10px rgba(0, 0, 0, 0.45),
    -15px 0px 10px -10px rgba(0, 0, 0, 0.45);
}

.block-item-container .title-row {
  margin-bottom: 10px;
}

.block-item-container .description>p {
  padding-left: 25px;
}

.block-container ul {
  margin-top: 0;
}

.block-item-container ul {
  margin-top: 6px
}

p {
  margin: 0;
}

.d-flex {
  display: flex;
}

.fs-subtitle {
  font-size: 14px;
}

.color-subtitle {
  color: #0c5394
}

.d-flex.space-between {
  justify-content: space-between;
}

.resume-content .resume-content-inner {
  color: #7a7b7d;
  font-size: 12px;
  line-height: 16px;
  display: flex;
}

.resume-content .resume-content-inner .primary-section {
  width: 75%;
  padding: 12px;
  padding-bottom: 0;
}

.resume-content .resume-content-inner .secondary-section {
  width: 25%;
  background-color: #e9f5ff;
  padding: 12px;
  padding-bottom: 0;
}

.no-print {
  display: none;
}

.resume-content .resume-content-inner .block-container {
  margin-bottom: 18px;
}

.resume-content .resume-content-inner .block-heading {
  color: #0c5394;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0;
  border-bottom: 1px solid #e2ebf3;
  padding-bottom: 8px;
  margin-bottom: 8px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@page {
  margin: 7mm;
}