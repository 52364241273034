.resume-title-container {
    margin-bottom: 20px;
}

.resume-title-container .resume-title-name {
    font-size: 30px;
    color: #095393;
    margin-top: 0;
    margin-bottom: 15px;
}

.resume-title-container .resume-title-title {
    font-size: 16px;
}